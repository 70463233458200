<script>
	import { onMount } from 'svelte';
	import { _ } from 'svelte-i18n';
	import AuthorizeLayout from '../../../lib/layout/AuthorizeLayout.svelte';
	import ChooseManaged from '../../../lib/ChooseManaged.svelte';
	import { getConsent, getManagedLogos } from '../../../utils/api-calls';
	import { data, showSpinner, notification } from '../../../stores';
	import { logPlausibleEvent } from '../../../utils/helper';
	import { replace as replaceRoute } from 'svelte-spa-router';

	const states = {
		PICK_IMAGE: false
	};
	let managedLogos = null;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if ($data.chooseManagedLogo) {
			try {
				managedLogos = await getManagedLogos($data.chooseManagedLogo.id);
				states.PICK_IMAGE = true;
			} catch (err) {
				console.error(err);
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
		}

		if (!$data.chooseManagedLogo && !$data.chooseWhoManages) return replaceRoute('/');

		logPlausibleEvent({ u: '/wizard/managed' });

		$showSpinner = false;
	});
</script>

<AuthorizeLayout showAppInfo={false} unstack={false} showFooter={false} showCancelConsent={false}>
	<ChooseManaged {states} {managedLogos} />
</AuthorizeLayout>
