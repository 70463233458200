<script>
	import { onDestroy, onMount } from 'svelte';
	import {
		data,
		showSpinner,
		notification,
		isRemoteAuthClient,
		showUpgradePreferredModal,
		mergeAccounts
	} from '../../stores.js';
	import { getConsent, postLoginProvider } from '../../utils/api-calls.js';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import {
		clearSessionStorage,
		getRecommendedProviders
		// promptForPasskey
	} from '../../utils/helper.js';
	import { logPlausibleEvent } from '../../utils/helper.js';
	import logins from '../../../../../svr/providers/logins.json';
	import AuthorizeLayout from '../../lib/layout/AuthorizeLayout.svelte';
	import LoginAuthorize from '../../lib/LoginAuthorize.svelte';

	const isProd = window.location.hostname === 'wallet.hello.coop';

	const provider_hints = {
		shown: [],
		hidden: []
	};

	let authCancelledAtInitClient = false;
	let authCancelledAtRemoteClient = false;

	let evtSource;
	let qrLinkScanned = false;
	let providerHints;

	let showPasskeyLogin = false;

	//on logout, get original query params from session storage to make get consent call
	const authorizeQueryParams = sessionStorage.getItem('authorize_query_params');

	onMount(async () => {
		$showSpinner = true;

		//remove all outstanding modals
		$showUpgradePreferredModal = null;
		$mergeAccounts = null;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent(authorizeQueryParams || '');
			} catch (err) {
				console.error(err);
				//show error state at root page
				return replaceRoute('/');
			}
		}

		//auto-login if login_hint with known managed account
		const loginHintManaged = $data?.login_hint?.managed;
		const isLoginHintKnownManaged = loginHintManaged?.id && loginHintManaged?.slug !== 'email';
		if (loginHintManaged && isLoginHintKnownManaged) {
			try {
				$showSpinner = true;
				const { redirect } = await postLoginProvider({
					slug: loginHintManaged.slug,
					body: {
						accountToUse: 'managed',
						accountSelected: 'managed',
						login_hint: loginHintManaged.login_hint
					}
				});
				return (window.location.href = redirect);
			} catch (err) {
				//auto login failed - let user manually log in
				console.error('auto login failed, user needs to manually login', err);
				$showSpinner = false;
			}
		}

		if (
			($data?.isPersonalLoggedIn || $data?.isManagedLoggedIn) &&
			'release' in $data &&
			!$data.login_hint &&
			!$data?.release?.requiresPersonal
		) {
			await logPlausibleEvent({ u: '/login/auto' });
			return replaceRoute('/');
		}

		//update UI with provider_hints query param
		useProviderHints();

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				if (!isProd) {
					console.log('keep-alive: ' + event.data);
				}
			});
		}

		//uncomment to start prompting for passkey
		// showPasskeyLogin = await promptForPasskey();

		logUserReleaseFunnelPlausibleEvent();
		clearSessionStorage(['app', 'authorize_query_params', 'az_release_funnel']);
		logPlausibleEvent({ u: '/login' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	function logUserReleaseFunnelPlausibleEvent() {
		//Start of New User Release Funnel
		//event not already sent + no Hellō cookie
		if (
			!sessionStorage.az_release_funnel &&
			!$data.isPersonalLoggedIn &&
			!$data.isManagedLoggedIn &&
			!$data.preferred
		) {
			const allRecommendedProviders = getRecommendedProviders(
				provider_hints.shown,
				provider_hints.hidden
			);
			if (showPasskeyLogin) {
				allRecommendedProviders.push('passkey');
			}
			const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'client_id'
			);
			const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'redirect_uri'
			);
			let redirect;
			try {
				redirect = new URL(redirect_uri)?.hostname;
			} catch (err) {
				console.error(err);
			}
			logPlausibleEvent({
				n: 'AZ Request',
				p: { client_id, recommended_providers: allRecommendedProviders.sort().join(' '), redirect },
				u: '/login'
			});
			sessionStorage.setItem('az_release_funnel', 'az_request');
		}
	}

	function useProviderHints() {
		const searchParams = new URLSearchParams(authorizeQueryParams);
		providerHints =
			searchParams.get('provider_hint') ||
			searchParams.get('provider_hints') ||
			$data.provider_hint ||
			$data.provider_hints;
		if (providerHints) {
			const possibleSlugs = [
				...logins.filter((i) => !i.no_login).map((i) => i.slug),
				'email',
				'ethereum',
				'qrcode',
				'passkey'
			];
			let unknownSlugs = [];
			for (let provider of providerHints.split(' ')) {
				if (provider && provider.endsWith('--')) {
					provider = provider.substring(0, provider.length - 2);

					if (!possibleSlugs.includes(provider)) {
						unknownSlugs.push(provider);
						continue;
					}

					provider_hints.hidden = [...provider_hints.hidden, provider];
					continue;
				}

				if (!possibleSlugs.includes(provider)) {
					unknownSlugs.push(provider);
					continue;
				}

				provider_hints.shown = [...provider_hints.shown, provider];
			}

			if (unknownSlugs.length) {
				console.warn(
					`Unsupported provider_hint values: ${unknownSlugs.join(
						', '
					)}\nPlease see https://www.hello.dev/documentation/provider-hint.html#possible-slug-values for supported values.`
				);
			}
		}
	}
</script>

<AuthorizeLayout
	showTitleBar={!qrLinkScanned && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	showCancelConsent={false}
>
	<LoginAuthorize showQRLogin={true} />
</AuthorizeLayout>
