<script>
	import { _ } from 'svelte-i18n';

	export let app = {};
</script>

<div class="flex flex-col items-center unstack:items-start stack:items-center my-6">
	<span>{$_('By continuing, you agree to the')}</span>
	<span class="block text-xl font-medium my-0.5" data-test="app-info-name">{app.name}</span>
	<div class="flex gap-x-4 text-sm pb-1">
		{#if app.tos_uri}
			<a href={app.tos_uri} class="underline" data-test="app-info-tos">{$_('Terms of Service')}</a>
		{:else}
			<span class="text-red-500">{$_('No Terms of Service')}</span>
		{/if}
		{#if app.pp_uri}
			<a href={app.pp_uri} class="underline" data-test="app-info-pp">{$_('Privacy Policy')}</a>
		{:else}
			<span class="text-red-500">{$_('No Privacy Policy')}</span>
		{/if}
	</div>
</div>
