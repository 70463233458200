<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data } from '../../stores';
	import { dedupe, makeLabel } from '../../utils/helper';
	import Dropdown from '../Dropdown.svelte';
	import VerifiedIcon from '../icon/VerifiedIcon.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import logins from '../../../../../svr/providers/logins.json';

	export let selected = '';
	export let expanded = false;

	export let handleDropdown = () => {};
	export let continueWithProvider = () => {};
</script>

<div data-test="verified-name-container" id="verified-name" class="space-y-1">
	<label for="verified-name" class="text-sm font-medium">
		{$_('Name')}
	</label>
	<div id="verified-name" class="box-shadow space-y-2 p-2 rounded-md">
		{#if selected && $data?.release?.verified_names}
			{@const verifiedNames = dedupe([...Object.keys($data.release.verified_names)]).sort(
				(a, b) =>
					Object.keys($data.release.verified_names[b]).length -
					Object.keys($data.release.verified_names[a]).length
			)}
			<div data-test="verified-name-container" class="relative flex items-center">
				<div class="w-full">
					<ul class="flex flex-col items-start space-y-2 mt-1">
						{#each verifiedNames as verified_name, index (verified_name)}
							{@const disabled =
								Object.keys($data.release.verified_names[verified_name]).length < 2}
							<li
								data-test="verified-name-{index}"
								class:opacity-50={disabled}
								class:pointer-events-none={disabled}
								class="flex items-center w-full"
							>
								<input
									id="verified-name-{verified_name}"
									name="verified-name"
									type="radio"
									bind:group={selected}
									value={verified_name}
									class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
									{disabled}
								/>
								<label
									for="verified-name-{verified_name}"
									class="ml-2 inline-flex items-center truncate w-full"
								>
									<span class="inline-block w-7 mr-0.5 flex-shrink-0">
										<VerifiedIcon unverified={disabled} />
									</span>
									<span class="truncate">
										{verified_name}
										{makeLabel($data.release.verified_names[verified_name])}
									</span>
								</label>
							</li>
						{/each}
					</ul>
				</div>
			</div>
		{/if}

		<div class="relative">
			<span data-test="link-more-name-cta" class="italic opacity-80 mb-1 block">
				Link another account to verify a different name
			</span>
			<Dropdown
				hasBackground={false}
				dataTest="add-provider-btn"
				ariaLabel={$_('Link another account')}
				{expanded}
				on:click={() => handleDropdown('verifiedNameAccountsDropdown')}
			>
				<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
					{$_('Link another account')}
				</div>

				{#if expanded}
					<ul class="space-y-2 p-2" transition:slide|local>
						{#each logins.filter((i) => i.claims.existing_name) as provider}
							<LoginProvider
								{provider}
								prefix={Array.isArray($data?.accounts) && $data?.accounts.includes(provider.slug)
									? 'Link another'
									: 'Link'}
								on:click={(e) => continueWithProvider(provider.slug, null, e.detail)}
							/>
						{/each}
					</ul>
				{/if}
			</Dropdown>
		</div>
	</div>
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}
	}
</style>
