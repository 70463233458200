<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data } from '../../stores';
	import { dedupe, makeLabel } from '../../utils/helper';
	import Dropdown from '../Dropdown.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import logins from '../../../../../svr/providers/logins.json';

	export let selected = '';
	export let expanded = false;

	export let handleDropdown = () => {};
	export let continueWithProvider = () => {};
</script>

<div data-test="existing-name-container" id="existing-name" class="space-y-1">
	<label for="existing-name" class="text-sm font-medium">
		{$_('Name')}
	</label>
	<div id="existing-name" class="box-shadow space-y-2 p-2 rounded-md">
		{#if selected && $data?.release?.existing_names}
			{@const existingNames = dedupe([...Object.keys($data.release.existing_names)]).sort(
				(a, b) =>
					Object.keys($data.release.existing_names[b]).length -
					Object.keys($data.release.existing_names[a]).length
			)}
			<div data-test="existing-name-container" class="relative flex items-center">
				<div class="w-full">
					<ul class="flex flex-col items-start space-y-2 mt-1">
						{#each existingNames as existing_name, index (existing_name)}
							<li data-test="existing-name-{index}" class="flex items-center w-full">
								<input
									id="existing-name-{existing_name}"
									name="existing-name"
									type="radio"
									bind:group={selected}
									value={existing_name}
									class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
								/>
								<label
									for="existing-name-{existing_name}"
									class="ml-2 inline-flex items-center truncate w-full"
								>
									<span class="truncate">
										{existing_name}
										{makeLabel($data.release.existing_names[existing_name])}
									</span>
								</label>
							</li>
						{/each}
					</ul>
				</div>
			</div>
		{/if}

		<div class="relative">
			<span data-test="link-more-name-cta" class="italic opacity-80 mb-1 block">
				{$_('Link another account to verify a different name')}
			</span>
			<Dropdown
				hasBackground={false}
				dataTest="add-provider-btn"
				ariaLabel={$_('Link another account')}
				{expanded}
				on:click={() => handleDropdown('existingNameAccountsDropdown')}
			>
				<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
					{$_('Link another account')}
				</div>

				{#if expanded}
					<ul class="space-y-2 p-2" transition:slide|local>
						{#each logins.filter((i) => i.claims.existing_name) as provider}
							<LoginProvider
								{provider}
								prefix={Array.isArray($data?.accounts) && $data?.accounts.includes(provider.slug)
									? 'Link another'
									: 'Link'}
								on:click={(e) => continueWithProvider(provider.slug, null, e.detail)}
							/>
						{/each}
					</ul>
				{/if}
			</Dropdown>
		</div>
	</div>
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}
	}
</style>
