<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data } from '../../stores';
	import { dedupe } from '../../utils/helper';
	import Dropdown from '../Dropdown.svelte';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import { putName } from '../../utils/api-calls';

	export let selected = '';
	export let expanded = false;

	export let handleDropdown = () => {};

	let value = '';
	let addPreferredNameAjax = false;

	async function addPreferredName() {
		addPreferredNameAjax = true;
		try {
			const res = await putName('nickname', value);
			$data.release.nicknames = res.nicknames;
			selected = $data.release.nicknames[0];
			expanded = false;
			value = '';
		} catch (err) {
			console.error(err);
		} finally {
			addPreferredNameAjax = false;
		}
	}
</script>

<div data-test="preferred-name-container" class="space-y-1">
	<label for="preferred-name" class="text-sm font-medium">
		{$_('Preferred Name (what you want to be called)')}
	</label>
	<Dropdown
		hasBackground={false}
		dataTest="preferred-name-dropdown-btn"
		ariaLabel={selected}
		id="preferred-name-dropdown"
		{expanded}
		on:click={() => handleDropdown('preferredNameDropdown')}
	>
		<div
			data-test="selected-preferred-name"
			class="px-2 w-full h-12 flex items-center justify-between"
			aria-hidden="true"
		>
			{selected}
		</div>
		{#if expanded}
			{@const nicknames = dedupe($data.release.nicknames).filter((i) => i !== selected)}
			<div class="px-indent rounded-md text-left w-full flex flex-col" transition:slide|local>
				<ul
					class="mt-0.5 {nicknames.length
						? 'border-y'
						: 'border-t'} border-[#808080] divide-y divide-[#808080]"
				>
					{#each nicknames as name, index (name)}
						<li>
							<button
								data-test={`preferred-name-${index}`}
								on:click={() => {
									selected = name;
									expanded = false;
								}}
								class="flex-shrink-0 h-12 w-full flex items-center justify-start"
							>
								{name}
							</button>
						</li>
					{/each}
				</ul>

				<form on:submit|preventDefault={addPreferredName} class="my-2">
					<input
						type="text"
						name="nickname"
						id="nickname"
						bind:value
						autocomplete="nickname"
						required
						placeholder={$_('enter what you want to be called')}
						class="px-[16px] sm:px-[18px] w-full h-12"
					/>
					<button
						data-test="add-preferred-name-btn"
						type="submit"
						disabled={!value.length || addPreferredNameAjax}
						class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
					>
						{#if addPreferredNameAjax}
							<SpinnerIcon css="h-5 w-5 text-white" />
						{:else if $data.release.nicknames.length}
							<span>{$_('Add another preferred name')}</span>
						{:else}
							<span>{$_('Add preferred name')}</span>
						{/if}
					</button>
				</form>
			</div>
		{/if}
	</Dropdown>
</div>

<style>
	ul li button:hover,
	ul li button:focus {
		box-shadow: 0px -2px 0px rgb(128, 128, 128), 0px 2px 0px rgb(128, 128, 128);
	}
</style>
