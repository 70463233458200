import Authorize from './index.svelte';
import Remote from './remote.svelte';
import Login from './login.svelte';

//Wizard
import VerifiedName from './wizard/verified-name.svelte';
import ExistingName from './wizard/existing-name.svelte';
import ExistingUsername from './wizard/existing-username.svelte';
import Name from './wizard/name.svelte';
import PreferredName from './wizard/preferred-name.svelte';
import FirstName from './wizard/first-name.svelte';
import LastName from './wizard/last-name.svelte';
import Email from './wizard/email.svelte';
import Phone from './wizard/phone.svelte';
import Recovery from './wizard/recovery.svelte';
import Link from './wizard/link.svelte';
import Passkey from './wizard/passkey.svelte';
import Managed from './wizard/managed.svelte';

export default {
	'/': Authorize,
	'/remote': Remote,
	'/login': Login,

	//Wizard
	'/wizard/passkey': Passkey,
	'/wizard/verified-name': VerifiedName,
	'/wizard/existing-name': ExistingName,
	'/wizard/existing-username': ExistingUsername,
	'/wizard/name': Name,
	'/wizard/preferred-name': PreferredName,
	'/wizard/first-name': FirstName,
	'/wizard/last-name': LastName,
	'/wizard/email': Email,
	'/wizard/phone': Phone,
	'/wizard/recovery': Recovery,
	'/wizard/ethereum': Link,
	'/wizard/twitter': Link,
	'/wizard/discord': Link,
	'/wizard/github': Link,
	'/wizard/gitlab': Link,
	'/wizard/managed': Managed,

	// The catch-all route must always be last
	'*': Login
};
