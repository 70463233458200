<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data } from '../../stores';
	import { DEFAULT_MANAGED_LOGO } from '../../constants';
	import { dedupe } from '../../utils/helper';
	import Dropdown from '../Dropdown.svelte';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import { putName } from '../../utils/api-calls';

	export let selected = '';
	export let expanded = false;

	export let handleDropdown = () => {};

	let value = '';
	let addValueExpand = false;
	let addFullNameAjax = false;

	async function addFullName() {
		addFullNameAjax = true;
		try {
			const res = await putName('name', value);
			$data.release.names = res.names;
			selected = $data.release.names[0];
			expanded = false;
			addValueExpand = false;
			value = '';
		} catch (err) {
			console.error(err);
		} finally {
			addFullNameAjax = false;
		}
	}
</script>

<div data-test="full-name-container" class="space-y-1">
	<label for="full-name" class="text-sm font-medium">
		{$_('Full Name (legal name)')}
	</label>
	{#if $data?.release?.managed?.name}
		{@const meta = $data?.release?.managed?._meta}
		<div
			class="px-4 overflow-hidden block h-12 flex items-center rounded-md bg-charcoal bg-opacity-10 dark:bg-opacity-100"
		>
			<span class="managed-rounded-square-sm">
				<img
					src={meta.logo || DEFAULT_MANAGED_LOGO}
					alt={meta.domain || 'logo'}
					class="w-4.5 max-h-[18px] object-contain"
				/>
			</span>
			<span class="ml-4">{$data?.release?.managed?.name}</span>
		</div>
	{:else if $data?.release?.update_scope}
		<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
			<div data-test="names-container" class="relative flex items-center">
				<div class="w-full">
					<ul class="flex flex-col items-start space-y-2 mt-1">
						{#each dedupe($data?.release?.names) as name, index (name)}
							<li data-test="name-{index}" class="flex items-center w-full">
								<input
									id="name-{name}"
									name="name"
									type="radio"
									bind:group={selected}
									value={name}
									class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
								/>
								<label for="name-{name}" class="ml-2.5 inline-flex items-center truncate w-full">
									<span class="truncate">
										{name}
									</span>
								</label>
							</li>
						{/each}
					</ul>
				</div>
			</div>

			<div class="relative">
				<Dropdown
					hasBackground={false}
					dataTest="add-name-btn"
					ariaLabel={$_('Add another name')}
					expanded={addValueExpand}
					on:click={() => (addValueExpand = !addValueExpand)}
				>
					<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
						{$_('Add another name')}
					</div>

					{#if addValueExpand}
						<form on:submit|preventDefault={addFullName} class="p-2" transition:slide|local>
							<input
								type="text"
								name="name"
								id="name"
								bind:value
								autocomplete="name"
								required
								placeholder={$_('enter your full name')}
								class="px-[16px] sm:px-[18px] w-full h-12"
							/>
							<button
								data-test="add-name-btn"
								type="submit"
								disabled={!value.length || addFullNameAjax}
								class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
							>
								{#if addFullNameAjax}
									<SpinnerIcon css="h-5 w-5 text-white" />
								{:else if $data.release.names.length}
									<span>{$_('Add another name')}</span>
								{:else}
									<span>{$_('Add name')}</span>
								{/if}
							</button>
						</form>
					{/if}
				</Dropdown>
			</div>
		</div>
	{:else}
		<Dropdown
			hasBackground={false}
			dataTest="name-dropdown-btn"
			ariaLabel={selected}
			id="name-dropdown"
			{expanded}
			on:click={() => handleDropdown('nameDropdown')}
		>
			<div
				data-test="selected-name"
				class="px-2 w-full h-12 flex items-center justify-between"
				aria-hidden="true"
			>
				{selected}
			</div>

			{#if expanded}
				{@const names = dedupe($data.release.names).filter((i) => i !== selected)}
				<div class="px-indent rounded-md text-left w-full flex flex-col" transition:slide|local>
					<ul
						class="mt-0.5 {names.length
							? 'border-y'
							: 'border-t'} border-[#808080] divide-y divide-[#808080]"
					>
						{#each names as name, index (name)}
							<li>
								<button
									data-test={`name-${index}`}
									on:click={() => {
										selected = name;
										expanded = false;
									}}
									class="flex-shrink-0 h-12 w-full flex items-center justify-start"
								>
									{name}
								</button>
							</li>
						{/each}
					</ul>
					<form on:submit|preventDefault={addFullName} class="my-2">
						<input
							type="text"
							name="name"
							id="name"
							bind:value
							autocomplete="name"
							required
							placeholder={$_('enter your full name')}
							class="px-[16px] sm:px-[18px] w-full h-12"
						/>
						<button
							data-test="add-name-btn"
							type="submit"
							disabled={!value.length || addFullNameAjax}
							class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
						>
							{#if addFullNameAjax}
								<SpinnerIcon css="h-5 w-5 text-white" />
							{:else if $data.release.names.length}
								<span>{$_('Add another name')}</span>
							{:else}
								<span>{$_('Add name')}</span>
							{/if}
						</button>
					</form>
				</div>
			{/if}
		</Dropdown>
	{/if}
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}
	}

	ul li button:hover,
	ul li button:focus {
		box-shadow: 0px -2px 0px rgb(128, 128, 128), 0px 2px 0px rgb(128, 128, 128);
	}
</style>
