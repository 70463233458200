<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data } from '../../stores';
	import { dedupe } from '../../utils/helper';
	import Dropdown from '../Dropdown.svelte';
	import VerifyPhone from '../VerifyPhone.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import logins from '../../../../../svr/providers/logins.json';
	import { getConsent } from '../../utils/api-calls';

	export let selected = '';
	export let expanded = false;
	export let phoneOTPState = false;

	export let handleDropdown = () => {};
	export let continueWithProvider = () => {};

	let addPhoneDropdown = false;

	async function verifyPhoneSuccess() {
		try {
			const res = await getConsent();
			if (!res.merge) {
				$data.release.phones = res.release.phones;
				selected = $data.release.phones[0];
			}
			expanded = false;
			if (addPhoneDropdown) {
				addPhoneDropdown = false;
			}
		} catch (err) {
			console.error(err);
		}
	}
</script>

<div data-test="phone-container" class="space-y-1">
	<label for="phone" class="text-sm font-medium">
		{$_('Verified Phone Number')}
	</label>
	{#if $data?.release?.update_scope}
		<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
			<div data-test="phones-container" class="relative flex items-center">
				<div class="w-full">
					<ul class="flex flex-col items-start space-y-2 mt-1">
						{#each dedupe($data?.release?.phones) as phone, index (phone)}
							<li data-test="phone-{index}" class="flex items-center w-full">
								<input
									id="phone-{phone}"
									name="phone"
									type="radio"
									bind:group={selected}
									value={phone}
									class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
								/>
								<label for="phone-{phone}" class="ml-2.5 inline-flex items-center truncate w-full">
									<span class="truncate">
										{window.intlTelInputUtils.formatNumber(
											phone,
											null,
											window.intlTelInputUtils.numberFormat.INTERNATIONAL
										)}
									</span>
								</label>
							</li>
						{/each}
					</ul>
				</div>
			</div>

			<div class="relative">
				<Dropdown
					hasBackground={false}
					dataTest="add-provider-btn"
					ariaLabel="Add another phone"
					expanded={addPhoneDropdown}
					on:click={() => (addPhoneDropdown = !addPhoneDropdown)}
				>
					<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
						Add another phone
					</div>

					{#if addPhoneDropdown}
						<ul class="space-y-2 p-2" transition:slide|local>
							<div class="pb-2">
								<VerifyPhone
									on:otp={() => (phoneOTPState = true)}
									on:success={verifyPhoneSuccess}
								/>
							</div>
							{#each logins.filter((i) => i.claims.verified_phone) as provider}
								<LoginProvider
									{provider}
									prefix={Array.isArray($data?.accounts) && $data?.accounts.includes(provider.slug)
										? 'Get another phone from'
										: 'Get phone from'}
									on:click={(e) => continueWithProvider(provider.slug, 'phone', e.detail)}
								/>
							{/each}
						</ul>
					{/if}
				</Dropdown>
			</div>
		</div>
	{:else}
		<Dropdown
			hasBackground={false}
			dataTest="phone-dropdown-btn"
			id="phone-dropdown"
			ariaLabel={window.intlTelInputUtils.formatNumber(
				selected,
				null,
				window.intlTelInputUtils.numberFormat.INTERNATIONAL
			)}
			{expanded}
			on:click={() => handleDropdown('phoneDropdown')}
		>
			<div
				data-test="selected-phone"
				class="px-2 w-full h-12 flex items-center justify-between"
				aria-hidden="true"
			>
				{window.intlTelInputUtils.formatNumber(
					selected,
					null,
					window.intlTelInputUtils.numberFormat.INTERNATIONAL
				)}
			</div>

			{#if expanded}
				{@const phones = dedupe($data.release.phones).filter((i) => i !== selected)}
				<div class="px-indent text-left w-full flex flex-col" transition:slide>
					<ul
						class="mt-0.5 {phones.length
							? 'border-y'
							: 'border-t'} border-[#808080] divide-y divide-[#808080]"
					>
						{#each phones as phone, index (phone)}
							<li>
								<button
									data-test={`phone-${index}`}
									class="flex-shrink-0 h-12 w-full flex items-center justify-start"
									on:click={() => {
										selected = phone;
										expanded = false;
									}}
								>
									{window.intlTelInputUtils.formatNumber(
										phone,
										null,
										window.intlTelInputUtils.numberFormat.INTERNATIONAL
									)}
								</button>
							</li>
						{/each}
					</ul>

					<div class="my-2">
						<VerifyPhone on:otp={() => (phoneOTPState = true)} on:success={verifyPhoneSuccess} />
					</div>

					{#if !phoneOTPState && logins.filter((i) => i.claims.verified_phone).length}
						<ul class="space-y-2 mb-2">
							{#each logins.filter((i) => i.claims.verified_phone) as provider}
								<li>
									<LoginProvider
										on:click={(e) => continueWithProvider(provider.slug, 'phone', e.detail)}
										{provider}
										prefix="Get phone from"
									/>
								</li>
							{/each}
						</ul>
					{/if}
				</div>
			{/if}
		</Dropdown>
	{/if}
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}
	}

	ul li button:hover,
	ul li button:focus {
		box-shadow: 0px -2px 0px rgb(128, 128, 128), 0px 2px 0px rgb(128, 128, 128);
	}
</style>
