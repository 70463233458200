<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data } from '../../stores';
	import { dedupe } from '../../utils/helper';
	import Dropdown from '../Dropdown.svelte';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import { putName } from '../../utils/api-calls';
	import { DEFAULT_MANAGED_LOGO } from '../../constants';

	export let selected = '';
	export let expanded = false;

	export let handleDropdown = () => {};

	let value = '';
	let addValueExpand = false;
	let addFirstNameAjax = false;

	async function addFirstName() {
		addFirstNameAjax = true;
		try {
			const res = await putName('given_name', value);
			$data.release.given_names = res.given_names;
			selected = $data.release.given_names[0];
			expanded = false;
			addValueExpand = false;
			value = '';
		} catch (err) {
			console.error(err);
		} finally {
			addFirstNameAjax = false;
		}
	}
</script>

<div data-test="first-name-container" class="space-y-1">
	<label for="first-name" class="text-sm font-medium">
		{$_('First Name (given name)')}
	</label>

	{#if $data?.release?.managed?.given_name}
		{@const meta = $data?.release?.managed?._meta}
		<div
			class="px-4 overflow-hidden block h-12 flex items-center rounded-md bg-charcoal bg-opacity-10 dark:bg-opacity-100"
		>
			<span class="managed-rounded-square-sm">
				<img
					src={meta.logo || DEFAULT_MANAGED_LOGO}
					alt={meta.domain || 'logo'}
					class="w-4.5 max-h-[18px] object-contain"
				/>
			</span>
			<span class="ml-4">{$data?.release?.managed?.given_name}</span>
		</div>
	{:else}
		<Dropdown
			hasBackground={false}
			dataTest="first-name-dropdown-btn"
			id="first-name-dropdown"
			ariaLabel={selected}
			{expanded}
			on:click={() => handleDropdown('firstNameDropdown')}
		>
			<div
				data-test="selected-first-name"
				class="px-2 w-full h-12 flex items-center justify-between"
				aria-hidden="true"
			>
				{selected}
			</div>

			{#if expanded}
				{@const givenNames = dedupe($data.release.given_names).filter((i) => i !== selected)}
				<div class="px-indent rounded-md text-left w-full flex flex-col" transition:slide|local>
					<ul
						class="mt-0.5 {givenNames.length
							? 'border-y'
							: 'border-t'} border-[#808080] divide-y divide-[#808080]"
					>
						{#each givenNames as name, index (name)}
							<li>
								<button
									data-test={`first-name-${index}`}
									on:click={() => {
										selected = name;
										expanded = false;
									}}
									class="flex-shrink-0 h-12 w-full flex items-center justify-start"
								>
									{name}
								</button>
							</li>
						{/each}
					</ul>

					<form on:submit|preventDefault={addFirstName} class="my-2">
						<input
							type="text"
							name="given-name"
							id="given-name"
							bind:value
							autocomplete="given-name"
							required
							placeholder={$_('enter your first name')}
							class="px-[16px] sm:px-[18px] w-full h-12"
						/>
						<button
							data-test="add-first-name-btn"
							type="submit"
							disabled={!value.length || addFirstNameAjax}
							class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
						>
							{#if addFirstNameAjax}
								<SpinnerIcon css="h-5 w-5 text-white" />
							{:else if $data.release.given_names.length}
								<span>{$_('Add another first name')}</span>
							{:else}
								<span>{$_('Add first name')}</span>
							{/if}
						</button>
					</form>
				</div>
			{/if}
		</Dropdown>
	{/if}
</div>

<style>
	ul li button:hover,
	ul li button:focus {
		box-shadow: 0px -2px 0px rgb(128, 128, 128), 0px 2px 0px rgb(128, 128, 128);
	}
</style>
