<script>
	import { replace as replaceRoute } from 'svelte-spa-router';
	import { data, notification, showSpinner, isRemoteAuthClient } from '../../../stores.js';
	import { onDestroy, onMount } from 'svelte';
	import { getConsent, postLinkProvider, deleteConsent } from '../../../utils/api-calls';
	import { logPlausibleEvent } from '../../../utils/helper.js';
	import logins from '../../../../../../svr/providers/logins.json';
	import LoginProvider from '../../../lib/LoginProvider.svelte';
	import AuthorizeLayout from '../../../lib/layout/AuthorizeLayout.svelte';

	const isProd = window.location.hostname === 'wallet.hello.coop';

	let authCancelledAtInitClient = false;
	let authCancelledAtRemoteClient = false;
	let evtSource;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isPersonalLoggedIn && !$data?.isManagedLoggedIn) return replaceRoute('/login');

		//we already got an existing_name
		if ($data.release?.existing_names?.length) return replaceRoute('/');

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				if (!isProd) {
					console.log('keep-alive: ' + event.data);
				}
			});
		}

		logPlausibleEvent({ u: '/wizard/existing-name' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute: 'existing-name',
				server
			});

			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	function handleWizardComplete() {
		return replaceRoute('/');
	}

	$: {
		if (hasVerifiedName) {
			handleWizardComplete();
		}
	}

	$: existingNames = $data?.release?.existing_names && Object.keys($data.release.existing_names);

	$: hasVerifiedName = Array.isArray(existingNames) && Boolean(existingNames?.length);

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				$notification = {
					text: 'Authorization was cancelled',
					type: 'error'
				};
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}
</script>

<AuthorizeLayout
	heading="Requires a name you already use"
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	{cancelConsent}
>
	<section class="max-w-md container mx-auto">
		<h2 data-test="page-cta" class="md:max-w-md text-lg font-medium my-6 mx-auto text-center block">
			Link another account to<br /> verify a name
		</h2>
		<ul class="space-y-3">
			{#each logins.filter((i) => !hasVerifiedName && i.claims.existing_name) as provider}
				<LoginProvider
					{provider}
					prefix={Array.isArray($data?.accounts) && $data?.accounts.includes(provider.slug)
						? 'Link another'
						: 'Link'}
					on:click={(e) => continueWithProvider(provider.slug, e.detail)}
				/>
			{/each}
		</ul>
	</section>
</AuthorizeLayout>
