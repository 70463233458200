<script>
	import { data } from '../../stores.js';
</script>

<div data-test="api-access-container" class="space-y-1">
	<label for="api-access" class="text-sm font-medium">API Access</label>
	<div
		id="api-access"
		data-test="publisher-api-access-container"
		class="px-2 py-2 relative w-full h-auto ring-1 ring-charcoal dark:ring-[#808080] ring-opacity-60 dark:ring-opacity-60 text-charcoal dark:text-[#D4D4D4] rounded-md"
	>
		<ul class="space-y-1">
			{#each ['Create', 'Read', 'Update', 'Delete'] as op}
				<li class="text-left flex items-center gap-x-2">
					<input
						data-test={`publisher-${op}-checkbox`}
						id={`publisher-${op}-checkbox`}
						type="checkbox"
						class="form-checkbox !w-4 !h-4 opacity-60 !pointer-events-none"
						checked={$data.release.console ||
							(['Create', 'Read'].includes(op) && $data.release.quickstart)}
						disabled
					/>
					<label for={`publisher-${op}-checkbox`} class="pointer-events-none">Publisher {op}</label>
				</li>
			{/each}
		</ul>
	</div>
	<div
		data-test="application-api-access-container"
		class="mt-2 px-2 py-2 relative w-full h-auto ring-1 ring-charcoal dark:ring-[#808080] ring-opacity-60 dark:ring-opacity-60 text-charcoal dark:text-[#D4D4D4] rounded-md"
	>
		<ul class="space-y-1">
			{#each ['Create', 'Read', 'Update', 'Delete'] as op}
				<li class="text-left flex items-center gap-x-2">
					<input
						type="checkbox"
						data-test={`application-${op}-checkbox`}
						id={`application-${op}-checkbox`}
						class="form-checkbox !w-4 !h-4 opacity-60 !pointer-events-none"
						checked={$data.release.console ||
							(['Create', 'Read'].includes(op) && $data.release.quickstart)}
						disabled
					/>
					<label for={`application-${op}-checkbox`} class="pointer-events-none"
						>Application {op}</label
					>
				</li>
			{/each}
		</ul>
	</div>
</div>
