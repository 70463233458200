<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import { data, notification, showSpinner, isRemoteAuthClient } from '../../../stores.js';
	import { onMount, onDestroy } from 'svelte';
	import { getConsent, deleteConsent, postLinkProvider } from '../../../utils/api-calls';
	import { logPlausibleEvent } from '../../../utils/helper.js';
	import logins from '../../../../../../svr/providers/logins.json';
	import LoginProvider from '../../../lib/LoginProvider.svelte';
	import Dropdown from '../../../lib/Dropdown.svelte';
	import MailIcon from '../../../lib/icon/MailIcon.svelte';
	import VerifyEmail from '../../../lib/VerifyEmail.svelte';
	import AuthorizeLayout from '../../../lib/layout/AuthorizeLayout.svelte';

	const isProd = window.location.hostname === 'wallet.hello.coop';

	let authCancelledAtInitClient = false;
	let authCancelledAtRemoteClient = false;
	let evtSource;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isPersonalLoggedIn && !$data?.isManagedLoggedIn) return replaceRoute('/login');

		//we already got an existing_username
		if ($data.release?.existing_usernames?.length) return replaceRoute('/');

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				if (!isProd) {
					console.log('keep-alive: ' + event.data);
				}
			});
		}

		logPlausibleEvent({ u: '/wizard/existing-username' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute: 'existing-username',
				server
			});

			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	function handleWizardComplete() {
		return replaceRoute('/');
	}

	async function verifyEmailSuccess() {
		try {
			continueWithEmail = false;
			$data = await getConsent();
		} catch (err) {
			console.error(err);
		}
	}

	$: existingUsernames =
		$data?.release?.existing_usernames && Object.keys($data.release.existing_usernames);

	$: hasVerifiedUsername = Array.isArray(existingUsernames) && Boolean(existingUsernames?.length);

	let continueWithEmail = false;

	$: {
		if (hasVerifiedUsername) {
			handleWizardComplete();
		}
	}
	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				$notification = {
					text: 'Authorization was cancelled',
					type: 'error'
				};
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}
</script>

<AuthorizeLayout
	heading="requires a username you already use"
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	{cancelConsent}
>
	<section class="max-w-md container mx-auto">
		<h2 data-test="page-cta" class="md:max-w-md text-lg font-medium my-6 mx-auto text-center block">
			Link another account to<br /> verify a username
		</h2>

		<ul class="space-y-3">
			{#each logins.filter((i) => !hasVerifiedUsername && i.claims.existing_username) as provider}
				<LoginProvider
					{provider}
					prefix={Array.isArray($data?.accounts) && $data?.accounts.includes(provider.slug)
						? 'Link another'
						: 'Link'}
					on:click={(e) => continueWithProvider(provider.slug, e.detail)}
				/>
				{#if provider.slug === 'google' && !hasVerifiedUsername}
					<Dropdown
						id="email-container"
						expanded={continueWithEmail}
						ariaLabel={Array.isArray($data?.accounts) && $data?.accounts.includes('email')
							? $_('Add another email')
							: $_('Add email')}
						dataTest="add-email-btn"
						on:click={() => {
							continueWithEmail = !continueWithEmail;
						}}
					>
						<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
							<MailIcon />
							<span class="block text-left" aria-hidden="true">
								{Array.isArray($data?.accounts) && $data?.accounts.includes('email')
									? $_('Add another email')
									: $_('Add email')}
							</span>
						</div>
						{#if continueWithEmail}
							<div class="px-3 pb-3 pt-1" transition:slide|local>
								<VerifyEmail on:success={verifyEmailSuccess} />
							</div>
						{/if}
					</Dropdown>
				{/if}
			{/each}
		</ul>
	</section>
</AuthorizeLayout>
