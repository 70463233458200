<script>
	import { slide } from 'svelte/transition';
	import { data } from '../../stores';
	import { dedupe } from '../../utils/helper';
	import Dropdown from '../Dropdown.svelte';
	import LoginProvider from '../LoginProvider.svelte';

	export let provider = '';
	export let label = '';
	export let selected = '';
	export let expanded = false;
	export let expandedKey = '';
	export let handleDropdown = () => {};
	export let continueWithProvider = () => {};
</script>

<div data-test="{provider}-container" class="space-y-1">
	<label for={provider} class="text-sm font-medium">{label}</label>
	{#if $data?.release?.update_scope}
		{@const key = provider + 's'}
		<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
			<div class="relative flex items-center">
				<div class="w-full">
					<ul class="flex flex-col items-start space-y-2 mt-1">
						{#each dedupe($data?.release?.[key]) as item, index (item.id)}
							<li data-test="{provider}-{index}" class="flex items-center w-full">
								<input
									id="{provider}-{item.id}"
									name={provider}
									type="radio"
									bind:group={selected}
									value={item}
									class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
								/>
								<label
									for="{provider}-{item.id}"
									class="ml-2.5 inline-flex items-center truncate w-full"
								>
									<div class="w-full flex items-center justify-between">
										<div class="flex items-center gap-x-2">
											<span aria-hidden="true">
												{item.username}
											</span>
										</div>
									</div>
								</label>
							</li>
						{/each}
					</ul>
				</div>
			</div>

			<LoginProvider
				on:click={(e) => continueWithProvider(provider, null, e.detail)}
				provider={{ slug: provider }}
				prefix="Add another"
			/>
		</div>
	{:else}
		{@const key = provider + 's'}
		<Dropdown
			hasBackground={false}
			dataTest="{provider}-dropdown"
			id="{provider}-dropdown"
			{expanded}
			ariaLabel={selected.username}
			on:click={() => handleDropdown(expandedKey)}
		>
			<div class="px-2 w-full h-12 flex items-center justify-between">
				<div data-test="selected-{provider}" class="flex items-center gap-x-3">
					<span aria-hidden="true">
						{selected.username}
					</span>
				</div>
			</div>

			{#if expanded}
				{@const items = dedupe($data.release[key], 'id').filter(
					(i) => i.username !== selected.username
				)}
				<div class="px-indent rounded-md text-left w-full flex flex-col" transition:slide|local>
					<ul
						class="mt-0.5 {items.length
							? 'border-y'
							: 'border-t'} border-[#808080] divide-y divide-[#808080]"
					>
						{#each items as item (item.id)}
							<li>
								<button
									on:click={() => {
										selected = item;
										expanded = false;
									}}
									class="flex-shrink-0 h-12 w-full flex items-center justify-start"
								>
									<div class="flex items-center gap-x-3">
										<span>{item.username}</span>
									</div>
								</button>
							</li>
						{/each}
					</ul>

					<div class="my-2">
						<LoginProvider
							on:click={(e) => continueWithProvider(provider, null, e.detail)}
							provider={{ slug: provider }}
							prefix="Add another"
						/>
					</div>
				</div>
			{/if}
		</Dropdown>
	{/if}
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}
	}

	ul li button:hover,
	ul li button:focus {
		box-shadow: 0px -2px 0px rgb(128, 128, 128), 0px 2px 0px rgb(128, 128, 128);
	}
</style>
