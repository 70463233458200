<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data } from '../../stores';
	import { dedupe } from '../../utils/helper';
	import Dropdown from '../Dropdown.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import logins from '../../../../../svr/providers/logins.json';
	import MailIcon from '../icon/MailIcon.svelte';
	import VerifyEmail from '../VerifyEmail.svelte';
	import { getConsent } from '../../utils/api-calls';

	export let selected = '';
	export let expanded = false;
	export let handleDropdown = () => {};
	export let continueWithProvider = () => {};

	let continueWithEmailForExisting = false;

	async function verifyEmailSuccess() {
		try {
			const res = await getConsent();
			if (!res.merge) {
				$data.release.emails = res.release.emails;
				$data.release.accounts = res.release.accounts;
				if ($data.release.existing_usernames) {
					$data.release.existing_usernames = res.release.existing_usernames;
				}
				selected = $data.release.emails[0];
			}
			expanded = false;
			continueWithEmailForExisting = false;
		} catch (err) {
			console.error(err);
		}
	}
</script>

<div data-test="existing-username-container" id="existing-username" class="space-y-1">
	<label for="existing-username" class="text-sm font-medium">
		{$_('Username')} @verified.coop
	</label>
	<div id="existing-username" class="box-shadow space-y-2 p-2 rounded-md">
		{#if selected && $data?.release?.existing_usernames}
			{@const existingUsernames = dedupe([...Object.keys($data.release.existing_usernames)])}
			<div data-test="existing-username-container" class="relative flex items-center">
				<div class="w-full">
					<ul class="flex flex-col items-start space-y-2 mt-1">
						{#each existingUsernames as existing_username, index (existing_username)}
							<li data-test="existing-username-{index}" class="flex items-center w-full">
								<input
									id="existing-username-{existing_username}"
									name="existing-username"
									type="radio"
									bind:group={selected}
									value={existing_username}
									class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
								/>
								<label
									for="existing-username-{existing_username}"
									class="ml-2.5 inline-flex items-center truncate w-full"
								>
									<span class="truncate">
										@{existing_username}@verified.coop
									</span>
								</label>
							</li>
						{/each}
					</ul>
				</div>
			</div>

			<div class="relative">
				<span data-test="link-more-username-cta" class="italic opacity-80 mb-1 block">
					Link another account to verify a different username
				</span>
				<Dropdown
					hasBackground={false}
					dataTest="add-provider-btn"
					ariaLabel={$_('Link another account')}
					{expanded}
					on:click={() => handleDropdown('existingUsernameAccountsDropdown')}
				>
					<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
						{$_('Link another account')}
					</div>

					{#if expanded}
						<ul class="space-y-2 p-2" transition:slide|local>
							{#each logins.filter((i) => i.claims.existing_username) as provider}
								<LoginProvider
									{provider}
									prefix={Array.isArray($data?.accounts) && $data?.accounts.includes(provider.slug)
										? 'Link another'
										: 'Link'}
									on:click={(e) => continueWithProvider(provider.slug, null, e.detail)}
								/>
								{#if provider.slug === 'google'}
									<Dropdown
										id="email-container"
										expanded={continueWithEmailForExisting}
										ariaLabel={$_('Add email')}
										dataTest="add-email-btn"
										on:click={() => {
											continueWithEmailForExisting = !continueWithEmailForExisting;
										}}
									>
										<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
											<MailIcon />
											<span class="block text-left" aria-hidden="true">
												{$_('Add email')}
											</span>
										</div>
										{#if continueWithEmailForExisting}
											<div class="px-2 pb-2 pt-1" transition:slide|local>
												<VerifyEmail on:success={verifyEmailSuccess} />
											</div>
										{/if}
									</Dropdown>
								{/if}
							{/each}
						</ul>
					{/if}
				</Dropdown>
			</div>
		{/if}
	</div>
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}
	}
</style>
