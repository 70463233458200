import Authorize from './Authorize.svelte';
import './index.css';
import './i18n.js';
import 'intl-tel-input/build/js/utils.js';

const app = new Authorize({
	target: document.body
});

export default app;
