<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute, location } from 'svelte-spa-router';
	import { data, showSpinner, notification, isRemoteAuthClient } from '../../../stores.js';
	import { onDestroy, onMount } from 'svelte';
	import {
		getConsent,
		deleteConsent,
		postLinkEth,
		postLinkEthChallenge,
		postLinkProvider
	} from '../../../utils/api-calls';
	import { getWallet, logPlausibleEvent, trimEthAddress } from '../../../utils/helper';
	import LoginProvider from '../../../lib/LoginProvider.svelte';
	import logins from '../../../../../../svr/providers/logins.json';
	import AuthorizeLayout from '../../../lib/layout/AuthorizeLayout.svelte';
	import EthereumProgressModal from '../../../lib/modal/EthereumProgressModal.svelte';
	// import { WALLETCONNECT_CONFIG } from "../../constants.js"
	// import { getAddressFromAccount } from '@walletconnect/utils';
	// import { WalletConnectModalSign } from '@walletconnect/modal-sign-html'

	// let web3ModalSign;

	const isProd = window.location.hostname === 'wallet.hello.coop';

	let provider;

	let ethereumProgressModal = null; //Will hold extension or walletconnect values
	let ethereumProgressNotifs = [];
	let authCancelledAtRemoteClient = false;
	let evtSource;
	let authCancelledAtInitClient = false;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isPersonalLoggedIn && !$data?.isManagedLoggedIn) return replaceRoute('/login');

		const providerSlug = $location.split('/')[2]; //gets the "ethereum" from /wizard/ethereum
		provider = [
			...logins,
			{
				// add ethereum obj since it does not exist in logins.json
				slug: 'ethereum',
				display: 'Ethereum'
			}
		].find((i) => i.slug === providerSlug);

		if (!provider)
			//reqeusted scope does not exist in logins.json - this should not happen but just in case
			return replaceRoute('/');

		//we already got the claim for the scope
		if ($data.release[provider.slug + 's']?.length) {
			return replaceRoute('/');
		}

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				if (!isProd) {
					console.log('keep-alive: ' + event.data);
				}
			});
		}

		logPlausibleEvent({ u: '/wizard/' + providerSlug });
		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	async function continueWithEthereumExtension() {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign')
			}
		];
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address) {
		let challenge, signature;

		try {
			const res = await postLinkEth(address);
			logPlausibleEvent({ u: `/start/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign')
				}
			];
		} catch (err) {
			console.error(err);
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Linking wallet')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				$notification = {
					text: $_(`You've rejected the sign request`),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			await postLinkEthChallenge(body);
			logPlausibleEvent({ u: `/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			$data = await getConsent();
			if (!$data.merge) {
				navigateTo('/');
			}
		} catch (err) {
			console.error(err);
		} finally {
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	// Note: Uncomment to show WalletConnect
	// let session;
	// async function continueWithWalletConnect() {
	// 	try {
	// 		web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 	} catch(err) {
	// 		console.error(err)
	// 		setTimeout(() => { //tbd : remove timeout - something is unsetting notification here
	// 			$notification = {
	// 			text: 'Something went wrong',
	// 				type: 'error'
	// 			}
	// 		}, 150)
	// 		return;
	// 	}

	// 	if (session) {
	// 		await web3ModalSign.disconnect({
	// 			topic: session.topic
	// 		})
	// 	}
	// 	session = await web3ModalSign.connect({
	// 		requiredNamespaces: {
	// 			eip155: {
	// 				methods: ['personal_sign'],
	// 				chains: ['eip155:1'],
	// 				events: []
	// 			}
	// 		}
	// 	})
	// 	const address = getAddressFromAccount(session.namespaces.eip155.accounts[0])
	// 	ethereumProgressModal = 'walletconnect';
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			text: $_('Wallet Connected ({address})', {
	// 				values: {
	// 					address: trimEthAddress(address)
	// 				}
	// 			}),
	// 			type: 'success',
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// 	setTimeout(() => {
	// 		continueWalletConnectSigning(address)
	// 	}, 1000)
	// }

	// async function continueWalletConnectSigning(address) {
	// 	let challenge, signature;
	// 	const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
	// 	try {
	// 		const res = await postLinkEth(address);
	// 		logPlausibleEvent({ u: `/start/link/ethereum/walletconnect/${slug}`, n: 'action' });
	// 		challenge = res.challenge;
	// 	} catch (err) {
	// 		console.error(err);
	// 		$notification = {
	// 			text: 'Something went wrong',
	// 			type: 'error'
	// 		};
	// 		return;
	// 	}
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	try {
	// 		signature = await web3ModalSign.request({
	// 			topic: session.topic,
	// 			chainId: 'eip155:1',
	// 			request: {
	// 				method: 'personal_sign',
	// 				params: [challenge, address]
	// 			}
	// 		});
	// 		ethereumProgressNotifs = [
	// 			...ethereumProgressNotifs,
	// 			{
	// 				text: $_('Message signed'),
	// 				type: 'success',
	// 				status: $_('Linking wallet')
	// 			}
	// 		];
	// 	} catch (err) {
	// 		console.info(err);
	// 		$notification = {
	// 			text: $_(`You've rejected the sign request`),
	// 			type: 'error'
	// 		};
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 		return;
	// 	}

	// 	const icon =
	// 		session.peer.metadata.icons[0] ||
	// 		(session.peer.metadata?.url === 'https://metamask.io/'
	// 			? 'https://cdn.hello.coop/images/metamask.svg'
	// 			: 'https://cdn.hello.coop/images/ethereum.svg');
	// 	const body = {
	// 		signature,
	// 		address,
	// 		icon,
	// 		name: session.peer.metadata.name
	// 	};

	// 	try {
	// 		await postLinkEthChallenge(body);
	// 		logPlausibleEvent({
	// 			u: `/link/ethereum/walletconnect/${slug}`,
	// 			n: 'action'
	// 		});
	// 		$data = await getConsent();
	// 		if (!$data.merge) {
	// 			navigateTo('/');
	// 		}
	// 	} catch (err) {
	// 		console.error(err);
	// 	} finally {
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 	}
	// }

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({ slug, server });
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				$notification = {
					text: 'Authorization was cancelled',
					type: 'error'
				};
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			}
			// else if (ethereumProgressModal === 'walletconnect') {
			// 	continueWalletConnectSigning(e.detail);
			// }
		}}
	/>
{/if}

<AuthorizeLayout
	heading={provider?.slug === 'ethereum'
		? $_('Requires your ethereum address')
		: `Requires your ${provider?.display} username`}
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	{cancelConsent}
>
	<div class="mt-2">
		{#if provider?.slug === 'ethereum'}
			{#if window.ethereum?.isMetaMask}
				<LoginProvider
					on:click={continueWithEthereumExtension}
					provider={{ display: 'MetaMask', slug: 'metamask' }}
					prefix="Get from"
				/>
			{:else if window.ethereum}
				<LoginProvider
					on:click={continueWithEthereumExtension}
					provider={{ display: 'Ethereum', slug: 'ethereum' }}
					prefix="Get from"
				/>
			{:else}
				<span class="text-sm text-center block text-red-500 my-6"
					>Please install and/or activate your Ethereum browser extension and reload this page.</span
				>
			{/if}

			<!-- Note: Uncomment to show WalletConnect -->
			<!-- <button
				class="mt-3 group w-full relative block btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
				on:click={continueWithWalletConnect}
			>
				<div class="flex items-center gap-x-4">
					<img
						src="https://cdn.hello.coop/images/walletconnect.svg"
						alt="WalletConnect"
						class="w-4.5 max-h-[18px]"
					/>
					<span class="block text-left">
						{$_('Get with {provider}', { values: { provider: 'WalletConnect' } })}
					</span>
				</div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="flex-shrink-0 {$locale && $locale.startsWith('ar')
						? 'mr-auto rotate-90'
						: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform group-focus:stroke-3 group-hover:stroke-3"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					stroke-width="2"
				>
					<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
				</svg>
			</button> -->
		{:else}
			<LoginProvider
				on:click={(e) => continueWithProvider(provider.slug, e.detail)}
				{provider}
				prefix="Get username from"
			/>
		{/if}
	</div>
</AuthorizeLayout>
