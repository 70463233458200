<script>
	import { _ } from 'svelte-i18n';
	import FullPageModal from './FullPageModal.svelte';
	import CloseIcon from '../icon/CloseIcon.svelte';

	export let deleteQRCode = () => {};
	export let qrSvg;
	export let qrLink;

	let qrLinkCopied = false;

	async function copyQRLink() {
		qrLinkCopied = true;
		await navigator.clipboard.writeText(qrLink);
		setTimeout(() => {
			qrLinkCopied = false;
		}, 1000);
	}
</script>

<FullPageModal on:close={deleteQRCode}>
	<div class="flex justify-center">
		<h1 class="text-xl">{$_('Scan QR Code')}</h1>
		<button class="absolute right-5" on:click={deleteQRCode}>
			<CloseIcon css="h-6 w-6" />
		</button>
	</div>
	<div class="w-full dark:invert">
		{@html qrSvg}
	</div>
	<button on:click={copyQRLink}
		>{qrLinkCopied ? 'Copied to clipboard!' : 'Copy to clipboard'}</button
	>
</FullPageModal>
